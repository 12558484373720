import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'

Modal.setAppElement('#settings-modal')

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'opacity 0.3s ease',
    zIndex: '1040',
  },
  content: {
    marginBotton: '8px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '25%',
    maxWidth: '500px',
    padding: '20px',
    transition: 'transform 0.3s ease',
    zIndex: '1041',
  },
}

const mobileCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    transition: 'opacity 0.3s ease',
    overflow: 'hidden',
    zIndex: '1040',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '500px',
    borderRadius: '8px',
    height: 'auto',
    padding: '20px',
    transition: 'transform 0.3s ease',
    zIndex: '1041',
  },
}

export function LocalSettingsModal({
  currentCurrency,
  availableCurrencies,
  currentMileage,
  availableMileage,
}) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState(currentCurrency)
  const [selectedMileage, setSelectedMileage] = useState(currentMileage)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const closeModal = () => {
    setIsOpen(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)
    const data = {
      currency: selectedCurrency,
      mileage: selectedMileage,
      ref: window.location.href,
    }

    App.Request.post('/local_settings/set_regional_preferences', data)
      .then((response) => {
        const { status } = response

        if (status === 200) {
          closeModal()
          window.location.reload()
        }
      })
      .catch(() => null)
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  useEffect(() => {
    const triggerElement = document.getElementById('open-settings-modal')
    if (triggerElement) {
      const openModal = (e) => {
        e.preventDefault()
        setIsOpen(true)
      }
      triggerElement.addEventListener('click', openModal)

      return () => triggerElement.removeEventListener('click', openModal)
    }
  }, [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick
        style={App.State.isMobile ? mobileCustomStyles : customStyles}
      >
        <h2>{I18n.t('local_settings.title')}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="currency-select" className="form-label">
              {I18n.t('local_settings.currency_select')}
            </label>
            <select
              id="currency-select"
              className="form-select"
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
            >
              {availableCurrencies.map((currency) => (
                <option key={currency} value={currency}>
                  {currency}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="mileage-select" className="form-label">
              {I18n.t('local_settings.mileage_select')}
            </label>
            <select
              id="mileage-select"
              className="form-select"
              value={selectedMileage}
              onChange={(e) => setSelectedMileage(e.target.value)}
            >
              {availableMileage.map((mileage) => (
                <option key={mileage} value={mileage}>
                  {mileage}
                </option>
              ))}
            </select>
          </div>
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary mx-1"
              style={
                App.State.isMobile
                  ? {
                      minWidth: '120px',
                      maxWidth: '150px',
                      width: 'auto',
                      fontSize: 'clamp(0.75rem, 4vw, 1rem)',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      lineHeight: '1.2',
                    }
                  : {}
              }
              onClick={closeModal}
            >
              {I18n.t('local_settings.cancel_button')}
            </button>
            <button
              type="submit"
              className="btn btn-primary mx-1"
              style={
                App.State.isMobile
                  ? {
                      minWidth: '120px',
                      maxWidth: '150px',
                      width: 'auto',
                      fontSize: 'clamp(0.75rem, 4vw, 1rem)',
                      whiteSpace: 'normal',
                      wordWrap: 'break-word',
                      lineHeight: '1.2',
                    }
                  : {}
              }
              disabled={isSubmitting}
            >
              {isSubmitting
                ? 'Saving...'
                : I18n.t('local_settings.save_button')}
            </button>
          </div>
        </form>
      </Modal>
    </>
  )
}
