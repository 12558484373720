import { render } from 'react-dom'
import { LocalSettingsModal } from '../components/LocalSettingsModal'

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('settings-modal')

  if (element) {
    const settings = {
      currentCurrency: element.dataset.currency,
      availableCurrencies: JSON.parse(element.dataset.availableCurrencies),
      currentMileage: element.dataset.currentMileage.toLowerCase(),
      availableMileage: JSON.parse(element.dataset.availableMileages),
    }

    render(
      <LocalSettingsModal
        currentCurrency={settings.currentCurrency}
        availableCurrencies={settings.availableCurrencies}
        currentMileage={settings.currentMileage}
        availableMileage={settings.availableMileage}
      />,
      element
    )
  } else {
    console.error('#settings-modal element not found')
  }
})
